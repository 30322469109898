import {
    CSSProperties,
    ReactElement
} from "react";

import "./index.scss";

type propsType = Readonly<{
    currentPage: number,
    imageCache: Array<string>,
    next: () => void,
}>;

export default function TitlePage(props: propsType): ReactElement {
    const {
        currentPage,
        imageCache,
        next,
    } = props;

    return <div id="titlePage" className="page" data-show={currentPage < 2}>
        {
            imageCache.map((data, i) => <img
                key={i}
                src={data}
                data-show={currentPage == i}
                style={{
                    "--order": i
                } as CSSProperties}
            />)
        }
        <button className="btn" onClick={next} />
    </div>
}
