import {
    ReactElement
} from "react";

import "./index.scss";

type propsType = Readonly<{
    show: boolean
}>;

export default function LoadingPage(props: propsType): ReactElement {
    const {
        show
    } = props;

    return <div id="loading" data-show={show}>
        <div className="text">Loading...</div>
    </div>
}
