import axios, { AxiosProgressEvent } from "axios";
import {
    CSSProperties,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";

import QuestionPage from "./view/QuestionPage";

import imageToString from "utils/imageToString";

import "./App.scss";
import TitlePage from "view/TitlePage";
import LoadingPage from "view/LoadingPage";
import ResultPage from "view/ResultPage";
import getResult from "utils/getResult";

export default function App(): ReactElement {
    const [progress, setProgress] = useState<Array<number>>([0, 0, 0, 0, 0, 0, 0, 0]);
    const [result, setResult] = useState<Array<0 | 1>>([]);
    const [resultProgress, setResultProgress] = useState<number>(0);
    const [resultCache, setResultCache] = useState<string>("");
    const [current, setCurrent] = useState<number>(0);
    const [titleCache, setTitleCache] = useState<Array<string>>(["", ""]);
    const [questionCache, setQuestionCache] = useState<Array<string>>(["", "", "", "", "", ""]);

    const loadingProgress = useMemo(() => {
        return progress.reduce((a, b) => a + b) / 8;
    }, [progress]);

    const downloadProgress = useCallback((i: number) => (progressEvent: AxiosProgressEvent) => setProgress(
        value => {
            let newValue = Array.from(value);
            newValue[i] = progressEvent.progress === undefined ? 1 : progressEvent.progress;
            return newValue;
        }
    ), []);

    useEffect(() => {
        for (let i = 0; i < 2; i++) {
            axios.get(
                `${process.env.PUBLIC_URL}/img/T${i + 1}.jpg`,
                {
                    responseType: "arraybuffer",
                    onDownloadProgress: downloadProgress(i)
                }
            ).then((response) => {
                setTitleCache(value => {
                    let newValue = Array.from(value);
                    newValue[i] = imageToString(response.data)
                    return newValue;
                })
            });
        }

        for (let i = 0; i < 6; i++) {
            axios.get(
                `${process.env.PUBLIC_URL}/img/Q${i + 1}.png`,
                {
                    responseType: "arraybuffer",
                    onDownloadProgress: downloadProgress(2 + i)
                }
            ).then((response) => {
                setQuestionCache(value => {
                    let newValue = Array.from(value);
                    newValue[i] = imageToString(response.data)
                    return newValue;
                })
            });
        }
    }, []);

    useEffect(() => {
        if (result.length < 6) return;
        axios.get(
            `${process.env.PUBLIC_URL}/img/${getResult(result)}.png`,
            {
                responseType: "arraybuffer",
                onDownloadProgress: progressEvent => setResultProgress(progressEvent.progress ?? 0)
            }
        ).then((response) => {
            setResultCache(imageToString(response.data));
        });
    }, [result]);

    return (
        <div id="app">
            <div
                className="content"
                data-loaded={loadingProgress == 1 && (result.length < 6 || resultProgress == 1)}
                style={{
                    "--progress": result.length < 6 ? loadingProgress : resultProgress
                } as CSSProperties}
            >
                <LoadingPage show={true} />
                <TitlePage
                    currentPage={current}
                    imageCache={titleCache}
                    next={() => setCurrent(v => v + 1)}
                />
                <QuestionPage
                    currentQuestion={current - 2}
                    imageCache={questionCache}
                    next={(option: 0 | 1) => {
                        setResult(v => [...v, option]);
                        setCurrent(v => v + 1);
                    }}
                />
                <ResultPage
                    show={resultProgress == 1}
                    imageCache={resultCache}
                />
            </div>
        </div>
    );
};
