import {
    CSSProperties,
    ReactElement,
} from "react";

import "./index.scss";

type propsType = Readonly<{
    currentQuestion: number,
    imageCache: Array<string>,
    next: (option: 0 | 1) => void,
}>;

export default function QuestionPage(props: propsType): ReactElement {
    const {
        currentQuestion,
        imageCache,
        next,
    } = props;

    return <div id="questionPage" className="page" data-show={currentQuestion >= 0}>
        {
            imageCache.map((data, i) => <img
                key={i}
                src={data}
                data-show={currentQuestion == i}
                style={{
                    "--order": i
                } as CSSProperties}
            />)
        }
        <button className="btn opt1" onClick={() => next(0)} />
        <button className="btn opt2" onClick={() => next(1)} />
    </div>;
};
