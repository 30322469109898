import {
    ReactElement,
} from "react";

import "./index.scss";

type propsType = Readonly<{
    show: boolean,
    imageCache: string,
}>;

export default function ResultPage(props: propsType): ReactElement {
    const {
        show,
        imageCache,
    } = props;

    return <div id="resultPage" className="page" data-show={show}>
        <img alt="result" src={imageCache} data-show={show} />
        <button className="copy" onClick={() => { navigator.clipboard.writeText(window.location.href).then(() => alert("已複製連結！")) }} />
        <button className="fb" onClick={() => {window.open("https://www.facebook.com/ncku.graduation", "_blank")}} />
        <button className="ig" onClick={() => {window.open("https://www.instagram.com/ncku_graduation_official", "_blank")}} />
        <button className="fbt" onClick={() => {window.open("https://www.facebook.com/ncku.graduation/posts/pfbid0mbe4gcnhoxVEC517YtJcJMTJuMq1WsDTATkNzFYf2sf6mX5KE7qehTeRKr7kEcDwl", "_blank")}} />
    </div>;
};
