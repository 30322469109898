export default function getResult(result: Array<0 | 1>): string {
    let out = result.map(v => v.toString()).join("");
    if (["111111", "111101", "101111", "000101", "101110", "101101"].includes(out)) {
        return "A0";
    }
    if (["001111", "011101", "111011", "001101", "011000", "100101", "101011"].includes(out)) {
        return "A1";
    }
    if (["100010", "100011", "101000", "111010", "100000", "100001", "111110"].includes(out)) {
        return "A2";
    }
    if (["001100", "001110", "011100", "011110", "101100", "111100"].includes(out)) {
        return "A3";
    }
    if (["000001", "000010", "000011", "001001", "001010", "001011", "100100"].includes(out)) {
        return "A4";
    }
    if (["110000", "110001", "110100", "110110", "110111", "110101", "110010"].includes(out)) {
        return "A5";
    }
    if (["000000", "010000", "010110", "010101", "001000", "101010"].includes(out)) {
        return "A6";
    }
    if (["011011", "011010", "010001", "010010", "010100", "010011"].includes(out)) {
        return "A7";
    }
    if (["011111", "110011", "011001", "010111", "000111", "000110"].includes(out)) {
        return "A8";
    }
    // if (["111001" ,"111000" ,"101001" ,"001000" ,"100111" ,"000100"].includes(out)) {
    return "A9";
}